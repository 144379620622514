export default {
    api: {
      url: 'https://betaapi.fitplus.biz/fitstar/api/v1/',
      urlV2: 'https://betaapi.fitplus.biz/fitstar/api/v2/',
      socket: 'https://betasocket.fitplus.biz',
      mode: 'cors'
    },
    firebaseConfig: {
      apiKey: "AIzaSyAWsoS7Oi-DOzghIJmbqCJq6-8IOrmGgKw",
      authDomain: "fitplus-1560243809365.firebaseapp.com",
      databaseURL: "https://fitplus-1560243809365.firebaseio.com",
      projectId: "fitplus-1560243809365",
      storageBucket: "fitplus-1560243809365.appspot.com",
      messagingSenderId: "488083150654",
      appId: "1:488083150654:web:f36492f450899f99f27e6f",
    }
  }